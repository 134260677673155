































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(props, { root, emit }) {
    const search = ref<HTMLInputElement | null>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false,
    });

    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        template: "",
        default: false,
      },
    });

    //  Start fetch single partner data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`summary-page-template/${root.$route.params.id}`)
        .then(({ data }) => {
          model.data.name = data.name || undefined;
          model.data.template = data.content || undefined;
          model.data.default = data.default;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single partner data

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        content: model.data.template || undefined,
        defaultTemplate: model.data.default ? model.data.default : undefined,
      };

      state.loading = true;

      axiosInstance
        .put(`summary-page-template/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 405:
          return "Nie można zmienić stanu domyślnego szablonu";
        case 409:
          return "Szablon już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      search,
    };
  },
});
